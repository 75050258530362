<template lang="pug">
  .basic-price-form
    span.condition-text {{ title }}
    .basic-price-form-items
      .basic-price-form-item
        .title {{ $t("plan_settings.basic_settings.sections.basic_price.day1") }}
        AppNumberInput(
          allow-null
          :invalid="value.day1.$error"
          v-model="value.day1.$model"
          :max="1000000"
        )
        .yen {{ $t("plan_settings.basic_settings.currency") }}
        span(
          v-if="hasUnappliedChanges('day1')"
          :title="$t('plan_settings.basic_settings.unapplied_changes', { value: appliedChanges.day1 })"
          v-b-tooltip.hover="{ variant: 'danger' }"
        )
          FaIcon.unapplied(
            icon="exclamation-circle"
          )
      .basic-price-form-item
        .title {{ $t("plan_settings.basic_settings.sections.basic_price.overnight") }}
        AppNumberInput(
          allow-null
          :invalid="value.overnight.$error"
          v-model="value.overnight.$model"
          :max="1000000"
        )
        .yen {{ $t("plan_settings.basic_settings.currency") }}
        span(
          v-if="hasUnappliedChanges('overnight')"
          :title="$t('plan_settings.basic_settings.unapplied_changes', { value: appliedChanges.overnight })"
          v-b-tooltip.hover="{ variant: 'danger' }"
        )
          FaIcon.unapplied(
            icon="exclamation-circle"
          )
      .basic-price-form-item
        .title {{ $t("plan_settings.basic_settings.sections.basic_price.days2") }}
        AppNumberInput(
          allow-null
          :invalid="value.days2.$error"
          v-model="value.days2.$model"
          :max="1000000"
        )
        .yen {{ $t("plan_settings.basic_settings.currency") }}
        span(
          v-if="hasUnappliedChanges('days2')"
          :title="$t('plan_settings.basic_settings.unapplied_changes', { value: appliedChanges.days2 })"
          v-b-tooltip.hover="{ variant: 'danger' }"
        )
          FaIcon.unapplied(
            icon="exclamation-circle"
          )
      .basic-price-form-item
        .title {{ $t("plan_settings.basic_settings.sections.basic_price.days3") }}
        AppNumberInput(
          allow-null
          :invalid="value.days3.$error"
          v-model="value.days3.$model"
          :max="1000000"
        )
        .yen {{ $t("plan_settings.basic_settings.currency") }}
        span(
          v-if="hasUnappliedChanges('days3')"
          :title="$t('plan_settings.basic_settings.unapplied_changes', { value: appliedChanges.days3 })"
          v-b-tooltip.hover="{ variant: 'danger' }"
        )
          FaIcon.unapplied(
            icon="exclamation-circle"
          )
      .basic-price-form-item
        .title {{ $t("plan_settings.basic_settings.sections.basic_price.late") }}
        AppNumberInput(
          allow-null
          :invalid="value.late.$error"
          v-model="value.late.$model"
          :max="1000000"
        )
        .yen {{ $t("plan_settings.basic_settings.currency") }}
        span(
          v-if="hasUnappliedChanges('late')"
          :title="$t('plan_settings.basic_settings.unapplied_changes', { value: appliedChanges.late })"
          v-b-tooltip.hover="{ variant: 'danger' }"
        )
          FaIcon.unapplied(
            icon="exclamation-circle"
          )
</template>

<script>
  import { isEmpty } from "lodash-es"

  export default {
    components: {
      AppNumberInput: () => import("@/components/elements/AppNumberInput")
    },

    props: {
      value: Object,

      appliedChanges: {
        type: Object,
        default: () => ({})
      },

      title: {
        type: String,
        default: ""
      }
    },

    methods: {
      hasUnappliedChanges(key) {
        return !isEmpty(this.appliedChanges) && this.value[key].$model !== this.appliedChanges[key]
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/price-management/basic-settings.sass"

  .basic-price-form
    +styled-inputs

    .unapplied
      color: $default-red
      margin-left: 5px

    &-items
      margin-top: 5px

    &-item
      align-items: center
      display: flex
      height: 40px

      .title
        width: 100px

      ::v-deep
        .app-number-input
          input
            width: 100px
            text-align: left

      .yen
        margin-left: 10px
</style>
